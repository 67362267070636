<template>
  <!-- Dialog si no se puede crear usuario -->

  <q-dialog persistent>
    <q-card :style="{ 'font-family': settings.typography }" >
      <q-card-actions align="center">
        <q-btn icon="close" color="red" size="25px" flat />
      </q-card-actions>
      <q-card-section>
        <div class="text-h6 text-center">
          {{ $t("Login.user-not-created") }}
        </div>
        <q-item-label class="text-center q-mt-md">{{
          $t("Login.error-register")
        }}</q-item-label>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          class="btn-home"
          :label="$t('Login.try-again')"
          :style="{ 'background-color': settings.color_accent }"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    settings: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.q-card {
  border-radius: 25px 25px 25px 0px;
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 10px;
  text-transform: capitalize;
  width: 100%;
  color: white;
}
</style>
